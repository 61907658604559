<template>
  <div>
    <Card class="shadow-none">
      <template v-slot:title>
        <h4>Confirmação:</h4>
      </template>
      <template v-slot:subtitle></template>
      <template v-slot:content>
        <div class="p-grid">
          <div class="p-col-12">
            <p style="color: red">
              <strong
                >Ao enviar minhas informações para análise através deste
                formulário, autorizo a Jacyara Seguros, através das seguradoras
                a consultar dados e informações junto aos órgãos de proteção ao
                crédito como SERASA, SPC, Boa Vista, entre outros, bem como,
                declaro sob as penas da lei, ter autorização para solicitar a
                consulta cadastral de todos os pretendentes citados. Declaro
                estar ciente da possibilidade de recusa em função da análise do
                risco, mesmo que comprovada capacidade financeira compatível à
                operação. Declaro ainda, que as informações prestadas são a
                expressão da verdade, pelas quais me responsabilizo.</strong
              >
            </p>

            <Checkbox id="aceito" v-model="aceito" :binary="true" /> 
            <label for="aceito" class="p-ml-2"><strong>Estou de Acordo</strong></label>

          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Voltar" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Enviar Para Análise"
            @click="complete()"
            icon="pi pi-check"
            iconPos="right"
            class="p-button-success"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    errorData: Array,
    processo: Object,
    imobiliaria: Number
  },
  emits: [
    "prev-page",
    "next-page",
    "complete"
  ],
  data() {
    return {
      fieldsError: [],

      aceito: false,
    };
  },
  mounted() {
    this.fieldsError = this.errorData;
  },
  watch: {

    errorData: function() {
      this.fieldsError = this.errorData;
    },

  },
  methods: {
    prevPage() {
      this.$emit("prev-page", { pageIndex: 3 });
    },
    complete() {
      if (this.validateForm()) {
        this.$emit("complete");
      }
    },
    validateForm() {
      this.fieldsError = [];

      if (!this.aceito) {
        this.fieldsError = ["aceito"];

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "É necessário aceitar os termos!",
          life: 5000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep(.p-togglebutton.p-button.p-highlight) {
  background-color: #689f38 !important;
  border-color: #689f38 !important;
}
</style>